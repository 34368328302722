var mena = window.mena || {};

(function ($) {
    'use strict';

    mena.main.init = function () {
        //mena.main.stickyHeader();
        mena.main.animations();
        mena.main.mobileMenu();
        mena.main.svg4everybody();
        mena.main.getNews();
        mena.main.languageSwitcher();
        mena.main.loadAssets();
        mena.main.playVideo();
        mena.main.pointsOfInterest();
        mena.main.socialShareToggle();
        mena.main.newsArchive();
        mena.main.scrollToContent();
        mena.main.heroTextAnimation();
        mena.main.membersArchive();
        mena.main.lineup();
    };

    mena.main.loadAssets = function () {
        if ($('.location__slider').length > 0 || $('.videos__slider').length > 0) {
            loadjs([menaObject.assets + '/js/swiper.min.js', menaObject.assets + '/css/swiper.css'], function () {
                mena.main.locationSlider();
                mena.main.videosSlider();
            });
        }
    };

    mena.main.lineup = function () {
        // Check if lineup_members exists
        if ($('.lineup__members').length > 0) {
			// Get all members
			let members = $('.lineup__member').toArray();

			//Add event listener to filter .lineup__menu-item which has data-stage attribute. This attribute is used to filter members by stage and exists as a css class on each member
            $('.lineup__menu-item[data-stage]').on('click', function () {
                // Get the stage name
                let stage = $(this).data('stage');

                if(!this.classList.contains('active')) {
                    // Remove active class from all .lineup__menu-item
                    $('.lineup__menu-item').removeClass('active');
                    this.classList.add('active');
                    // Company if the stage name is all
                    if (stage == 'all') {
                        $('.lineup__member').addClass('active');
                    } else {
                        // Hide all members
                        $('.lineup__member').removeClass('active');
                        // Show members with the same stage name
                        $(`.lineup__member.${stage}`).addClass('active');
                    }
                }

            });
		}

    };

    mena.main.heroTextAnimation = function () {
        if ($('.hero').length > 0) {
            $('.hero__text').removeClass('hidden');

            let initialFlag = true;
            let contentDuplicatedFlag = false;
            let initialWidth = 0;
            let heroText = String($('.hero__text').data('text')).split(', ');
            let output = '';

            if (heroText) {
                addHeroText();

                $('.hero__text').html(output);

                while (!heroHasEnoughText()) {
                    addHeroText();

                    $('.hero__text').html(output);
                    contentDuplicatedFlag = true;
                }

                if (!contentDuplicatedFlag) {
                    addHeroText();

                    $('.hero__text').html(output);
                }

                $('.hero__text').css('width', initialWidth);
            }

            function addHeroText() {
                heroText.forEach(function (text) {
                    output += `
                    <h3>${text}</h3>
                `;
                });
            }

            function heroHasEnoughText() {
                let windowWidth = $(window).width();
                let textWidth = 0;

                $('.hero__text')
                    .children()
                    .each(function (index, element) {
                        textWidth += $(element).width();

                        if ($(window).width() > 768) {
                            textWidth += 32;
                        } else {
                            textWidth += 16;
                        }
                    });

                if (initialFlag) {
                    initialWidth = textWidth;
                    initialFlag = false;
                }

                return textWidth > Number(windowWidth * 2);
            }
        }
    };

    mena.main.scrollToContent = function () {
        $('.hero__scroll-to-content').on('click', function () {
            let scrollTo = $(this).next();

            $([document.documentElement, document.body]).animate(
                {
                    scrollTop: $(scrollTo).offset().top,
                },
                0
            );
        });
    };

    mena.main.membersArchive = function () {
        let loadedPosts = [];
        let canLoadMore = true;

        let lineupSwiper;
        let activateSlide;
        let loadedSlides = [];

        $('.lineup__load-more-trigger').on('click', loadMoreMembers);

        $('.lineup').on('click', '.lineup__modal-close', function () {
            $('.lineup__modal').addClass('hidden');
            $('body').removeClass('modal-opened');
        });

        $('.lineup').on('click', function (e) {
            if(!$(e.target).closest('.lineup__slider').length > 0) {
                $('.lineup__modal').addClass('hidden');
                $('body').removeClass('modal-opened');
            }
        });

        document.addEventListener('keydown', function(e) {
            if(e.key == "Escape"){
                $('.lineup__modal').addClass('hidden');
                $('body').removeClass('modal-opened');

                $('.videos__modal').removeClass('active');
                $('.videos__modal iframe').attr('src', '');
            }
        });

        $('.lineup').on('click', '.lineup__member', function () {
            activateSlide = $(this).data('post-id');

            if ($('.lineup__modal').length > 0) {
                addLineupSlides();
            } else {
                createLineupModal();
            }
        });

        function loadMoreMembers() {
            loadedPosts = getLoadedPosts();

            if (canLoadMore) {
                $.ajax({
                    type: 'POST',
                    url: menaObject.ajaxurl,
                    dataType: 'html',
                    data: {
                        action: 'get_members',
                        loadedPosts: loadedPosts,
                    },

                    beforeSend: function () {
                        canLoadMore = false;
                        $('.lineup__loader').removeClass('hidden');
                    },

                    success: function (response) {
                        $('.lineup__loader').addClass('hidden');

                        response = JSON.parse(response);

                        if (response.haveMorePosts) {
                            canLoadMore = true;
                        } else {
                            canLoadMore = false;
                            $('.lineup__loader').hide();
                            $('.lineup__load-more-trigger').hide();
                        }

                        $('.lineup__members').append(response.posts);

                        ScrollTrigger.refresh();
                    },
                });
            }
        }

        function getLoadedPosts() {
            let loadedCards = $('.lineup__member').toArray();
            loadedPosts = [];

            loadedCards.forEach(function (card) {
                loadedPosts.push($(card).data('post-id'));
            });

            return loadedPosts;
        }

        function createLineupModal() {
            loadjs([menaObject.assets + '/js/swiper.min.js', menaObject.assets + '/css/swiper.css'], function () {
                let modal = `<div class="lineup__modal hidden">
                    <div class="lineup__modal-close"></div>
                </div>`;

                $('.lineup').append(modal);

                initLineupSlider();
            });
        }

        function initLineupSlider() {
            let slider = `
                <div class="swiper lineup__slider">
                    <div class="swiper-wrapper">
                    </div>

                    <div class="swiper-pagination"></div>

                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                    <div class="swiper-pagination"></div>
                </div>
            `;

            $('.lineup__modal').append(slider);

            lineupSwiper = new Swiper('.lineup__slider', {
                slidesPerView: 1.2,
                centeredSlides: true,
                spaceBetween: 16,
                slideToClickedSlide: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                keyboard: {
                    enabled: true,
                    onlyInViewport: false,
                },
                grabCursor: true,
                breakpoints: {
                    768: {
                        spaceBetween: 32,
                        slidesPerView: 1.4,
                    }
                }
            });

            addLineupSlides();
        }

        function addLineupSlides() {
            $('.lineup__member')
                .toArray()
                .forEach(function (member, index) {
                    if (loadedSlides[index] != $(member).data('post-id')) {
                        let id = $(member).data('post-id');
                        let image = $(member).find('.lineup__member-image').clone();
                        let title = '<h2>' + $(member).find('.lineup__member-title').text() + '</h2>';
                        let text = '<p>' + $(member).data('content') + '</p>';

                        let slide = document.createElement('div');

                        $(slide).addClass('swiper-slide');
                        $(slide).attr('data-slide-id', id);
                        $(slide).append(image);
                        $(slide).append(`<div>
                            ${title}
                            ${text}
                        </div>`);

                        lineupSwiper.appendSlide(slide);

                        loadedSlides.push($(member).data('post-id'));
                    }
                });

            let index = $('.lineup__slider .swiper-slide[data-slide-id="' + activateSlide + '"]').index();
            lineupSwiper.slideTo(index);

            setTimeout(() => {
                $('.lineup__modal').removeClass('hidden');
                $('body').addClass('modal-opened');
            }, 300);
        }
    };

    mena.main.newsArchive = function () {
        let loadedPosts = [];
        let canLoadMore = true;

        $('.blog__load-more-trigger').on('click', loadMoreNews);

        function loadMoreNews() {
            loadedPosts = getLoadedPosts();

            if (canLoadMore) {
                $.ajax({
                    type: 'POST',
                    url: menaObject.ajaxurl,
                    dataType: 'html',
                    data: {
                        action: 'get_news',
                        loadedPosts: loadedPosts,
                    },

                    beforeSend: function () {
                        canLoadMore = false;
                        $('.blog__loader').removeClass('hidden');
                    },

                    success: function (response) {
                        $('.blog__loader').addClass('hidden');

                        response = JSON.parse(response);

                        if (response.haveMorePosts) canLoadMore = true;
                        else canLoadMore = false;

                        $('.blog__posts').append(response.posts);

                        ScrollTrigger.refresh();
                    },
                });
            }
        }

        function getLoadedPosts() {
            let loadedCards = $('.news-card').toArray();
            loadedPosts = [];

            loadedCards.forEach(function (card) {
                loadedPosts.push($(card).data('post-id'));
            });

            return loadedPosts;
        }
    };

    mena.main.socialShareToggle = function () {
        $('.social-share-list__item:nth-child(n+4)').hide();

        $('.single-post__social-share-more').on('click', function () {
            if ($(this).hasClass('toggled')) {
                $('.social-share-list__item:nth-child(n+4)').hide();
                $(this).removeClass('toggled');
                $(this).html('+');
            } else {
                $(this).addClass('toggled');
                $(this).parent().find('.social-share-list__item').show();
                $(this).html('-');
            }
        });
    };

    mena.main.pointsOfInterest = function () {
        $('.js-points-of-interest-show-more').on('click', function () {
            if($(this).hasClass('toggled')) {
                $(this).removeClass('toggled');
                $(this).html('Show more');
                $(this).closest('section.points-of-interest').addClass('show-more');
            } else {
                $(this).addClass('toggled');
                $(this).html('Show less');
                $(this).closest('section.points-of-interest').removeClass('show-more');
            }
        });
    };

    mena.main.videosSlider = function () {
        var videosSlider = new Swiper('.videos__slider', {
            slidesPerView: 'auto',
            spaceBetween: 8,
            breakpoints: {
                1024: {
                    spaceBetween: 24,
                },
            },
        });
    };

    mena.main.locationSlider = function () {
        var locationSlider = new Swiper('.location__slider', {
            slidesPerView: 1.2,
            centeredSlides: true,
            spaceBetween: 8,
            breakpoints: {
                440: {
                    spaceBetween: 32,
                },
                640: {
                    slidesPerView: 1.4
                },
            },
            loop: false,
            loopFillGroupWithBlank: true,
            grabCursor: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    };

    mena.main.playVideo = function () {
        $('.swiper-slide').on('click', function () {
            $('.videos__modal').addClass('active');
            let embedURL = $(this).find('.videos__overlay').attr('data-embed-url');

            if ($('.videos__modal iframe').length == 0) {
                $('.videos__modal').append(`
                    <iframe src="${embedURL}" width="820" height="461" loading="lazy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                `);
            } else {
                $('.videos__modal iframe').attr('src', embedURL);
            }
        });

        $('.js-play-hero-video').on('click', function() {
            $('.videos__modal').addClass('active');
            let embedURL = $(this).attr('data-embed-url');

            if ($('.videos__modal iframe').length == 0) {
                $('.videos__modal').append(`
                    <iframe src="${embedURL}" width="820" height="461" loading="lazy" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                `);
            } else {
                $('.videos__modal iframe').attr('src', embedURL);
            }
        });

        $('.videos__modal-close, .videos__modal').on('click', function () {
            $('.videos__modal').removeClass('active');
            $('.videos__modal iframe').attr('src', '');
        });
    };

    mena.main.languageSwitcher = function () {
        $('.js-language-switcher-toggle').on('click', function () {
            $(this).toggleClass('active');
            $(this).siblings('.header__lang-switcher-dropdown').toggleClass('active');
        });
    };

    mena.main.animations = function () {
        gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
        ScrollTrigger.batch("[data-animation='fade-up']", {
            interval: 0.1, // time window (in seconds) for batching to occur.
            batchMax: 10,
            onEnter: (batch) =>
                gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    stagger: { each: 0.1 },
                    ease: 'power2.inOut',
                    overwrite: true,
                    duration: 0.5,
                }),
            start: 'top 80%',
            end: 'top top',
        });

        if ($('.blog__posts').length > 0) {
            ScrollTrigger.create({
                trigger: '.blog__load-more-trigger',
                start: 'top 120%',
                onToggle: (self) => {
                    $('.blog__load-more-trigger').trigger('click');
                },
            });
        }

        if ($('.lineup__members').length > 0) {
            ScrollTrigger.create({
                trigger: '.lineup__load-more-trigger',
                start: 'top 120%',
                onToggle: (self) => {
                    $('.lineup__load-more-trigger').trigger('click');
                },
            });
        }
    };

    mena.main.getNews = function () {
        let pageNumber;
        listenPagination();

        function listenPagination() {
            $('.all-news .page-numbers:not(.dots)').on('click', function (ev) {
                ev.preventDefault();
                if (!$(this).hasClass('current')) {
                    pageNumber = $(this).text();

                    if ($(this).hasClass('next')) {
                        pageNumber = parseInt($(this).siblings('.current').text()) + 1;
                    }

                    if ($(this).hasClass('prev')) {
                        pageNumber = parseInt($(this).siblings('.current').text()) - 1;
                    }
                    getNews(pageNumber);
                }
            });
        }

        function getNews(paginationNumber) {
            $.ajax({
                type: 'POST',
                url: menaObject.ajaxurl,
                dataType: 'html', // add data type
                data: {
                    action: 'get_blog_posts',
                    paginationNumber: paginationNumber,
                },

                beforeSend: function () {
                    $('.all-news__posts').addClass('loading');
                },

                success: function (response) {
                    $('.pagination').remove();
                    document.querySelector('.all-news__posts').scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest',
                    });
                    $('.all-news__posts').html(response);
                    $('.all-news__posts').removeClass('loading');
                    var pagination = $('.pagination').detach();
                    $('.pagination-holder').append(pagination);
                    listenPagination();
                },
            });
        }
    };

    mena.main.svg4everybody = function () {
        svg4everybody();
    };

    mena.main.mailchimp = function () {
        if ($('.js-mailchimp').length > 0) {
            loadjs(menaObject.assets + '/js/mailchimp-validate.js', function () {
                window.fnames = new Array();
                window.ftypes = new Array();
                fnames[0] = 'EMAIL';
                ftypes[0] = 'email';
            });
        }
    };

    mena.main.debounce = function (func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    mena.main.stickyHeader = function () {
        var stickyHeader = function () {
            var header = $('#header');
            $('#content').css('margin-top', header.outerHeight());

            if ($(window).scrollTop() > 1) {
                header.addClass('sticky');
            } else {
                header.removeClass('sticky');
            }
        };

        window.addEventListener('load', stickyHeader);
        window.addEventListener('scroll', mena.main.debounce(stickyHeader, 150));
        window.addEventListener('resize', mena.main.debounce(stickyHeader, 150));
    };

    mena.main.mobileMenu = function () {
        var respMenu = $('.resp-menu');
        var button = $('.js-menu-toggle');
        var body = $('body');

        button.click(function (e) {
            body.toggleClass('menu-open');
            button.toggleClass('menu-toggle--active');
        });

        /* Adding submenu arrows to the responsive navigation */
        respMenu.find('.sub-menu').each(function () {
            $(this).parent().append('<a class="submenu-button" href="javascript:void(0)"><svg class="arrow-down" width="15px" height="8px"><polygon fill="#fff" points="15.002,0 7.501,7.501 0,0 "></polygon></svg></a>');
        });

        $('.submenu-button').click(function () {
            $('.sub-menu').not($(this).siblings()).slideUp(300);
            $(this).prev('.sub-menu').slideToggle(100);
            $(this).toggleClass('rotate-arrow');
        });
    };

    return mena.main.init();
})($);
